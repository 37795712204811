import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Papaparse from "papaparse";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, FooterArea, TitleArea, ContentArea as BaseContextArea } from "components/LayoutParts";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import useBulkInsertUser from "pages/AtPort/api/useBulkInsertUser";
import AtPortUploadModal, { AtPortUploadModalConfig } from "pages/AtPort/component/AtPortUserUploadModal";
import {
  AT_PORT_IMPORT_CSV_ERROR_CODE,
  AT_PORT_IMPORT_CSV_ERROR,
  AT_PORT_IMPORT_CSV_ERROR_KEYS,
} from "pages/AtPort/constants/csvErrors";
import { generateUniqueUserInfoList } from "pages/AtPort/utils/parseUsers";
import { handleReadFileArrayBufferExcel } from "utils/dataFileUtil";

const UploadContainer = styled.div`
  min-width: 160px;
  height: 120px;
  background-color: ${color.white};
  display: flex;
  align-items: center;
  border: 1px solid ${color.border};
`;

const UploadWrap = styled.div`
  display: flex;
  margin: 0 48px;
  align-items: center;
  gap: 28px;
  color: ${color.text.gray};
  position: relative;
`;

const Label = styled.div`
  font-size: ${font.size16};
  width: 110px;
`;

const Message = styled.div<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  color: ${({ isBold }) => (isBold ? color.text.black : color.text.gray)};
`;

export const FormRow = styled.div<{ minHeight?: string; hideBorder?: boolean }>`
  width: 100%;
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: solid 1px ${color.border};
  border-bottom: ${({ hideBorder }) => (hideBorder ? "none" : `auto`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => props.minHeight};
  gap: 24px;
  font-size: ${font.size16};
`;

const PropertyEmptyInfo = styled.div`
  background-color: ${color.white};
  margin: 16px 0 0 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${color.text.gray};
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
  font-weight: 600;
  gap: 4px;
  display: flex;
  align-items: center;
`;

const MarginTop = styled.div`
  margin-top: 28px;
`;

const UpdateTitle = styled.div`
  font-size: ${font.size24};
  font-weight: 600;
`;

const ContentArea = styled(BaseContextArea)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  flex: 1;
`;

const ContentWrap = styled.div`
  flex: 1;
`;

const ContentLabel = styled.div`
  font-size: ${font.size20};
  font-weight: 600;
  padding: 18px 0 24px 0;
`;

const UserCountLabel = styled.span`
  font-size: ${font.size16};
  font-weight: 600;
`;

const UserInfoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export type AtPortImportUser = Omit<SalesforceUser, "id">;

const ImportUser = () => {
  const navigate = useNavigate();
  const { bulkInsertUser } = useBulkInsertUser();
  const [selected, setSelected] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [userCount, setUserCount] = useState(0);
  const [users, setUsers] = useState<AtPortImportUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [succeedCount, setSucceedCount] = useState(0);
  const [isUserModalShow, setIsUserModalShow] = useState(false);
  const [userModalConfig, setUserModalConfig] = useState<AtPortUploadModalConfig>({
    type: "loading",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const onOpenErrorModal = (errorText?: string) => {
    setIsVisible(true);
    setIsLoading(false);
    setModalConfig({
      subtitle: errorText ?? "フォーマットが正しくありません",
      main: {
        label: "閉じる",
        buttonType: "secondary",
        onClick: () => {
          setIsVisible(false);
        },
      },
      onClickOutside: () => {
        setIsVisible(false);
      },
    });
  };

  const handleInvalidFileTypeErrorModal = () => {
    setIsVisible(true);
    setIsLoading(false);
    setModalConfig({
      subtitle: "ファイル形式が正しくありません",
      main: {
        label: "閉じる",
        buttonType: "secondary",
        onClick: () => {
          setIsVisible(false);
        },
      },
      errorMessage: "CSVファイルを選択してください",
      onClickOutside: () => {
        setIsVisible(false);
      },
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if ((acceptedFiles[0] as File)?.type !== "text/csv") return handleInvalidFileTypeErrorModal();
    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      setIsLoading(true);
      const data = handleReadFileArrayBufferExcel(e);
      if (typeof data === "string") {
        const parser = Papaparse.parse(data);
        const rows = parser.data as string[][];
        const { users, count } = await generateUniqueUserInfoList(rows).catch(
          (e: { message: AT_PORT_IMPORT_CSV_ERROR_CODE }) => {
            onOpenErrorModal(
              AT_PORT_IMPORT_CSV_ERROR[
                (Object.keys(AT_PORT_IMPORT_CSV_ERROR) as AT_PORT_IMPORT_CSV_ERROR_KEYS[]).find(
                  (key: AT_PORT_IMPORT_CSV_ERROR_KEYS) => AT_PORT_IMPORT_CSV_ERROR[key].errorCode === e.message
                ) as AT_PORT_IMPORT_CSV_ERROR_KEYS
              ]?.message
            );
            throw new Error(e.message);
          }
        );
        setUserCount(count);
        setUsers(users);
      } else {
        setSelected(true);
        setFileName(acceptedFiles[0].name);
      }
      setSelected(true);
      setFileName(acceptedFiles[0].name);
      setIsLoading(false);
    };
    setIsLoading(false);
    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  const handleCloseModal = ({ callNavigate }: { callNavigate?: boolean }) => {
    setIsUserModalShow(false);
    callNavigate && navigate(`/at_port/user`);
  };

  const handleSubmit = async () => {
    setUploadCount(userCount);
    setUserModalConfig({
      type: "loading",
      onClickOutSide: () => handleCloseModal({}),
    });
    setIsUserModalShow(true);
    const { status, data } = await bulkInsertUser(users);

    if (status === 400) {
      setUserModalConfig({
        type: "validation-error",
        onClickOutSide: () => handleCloseModal({}),
      });
      return;
    }

    if (status === 201) {
      setFailedCount(data.failed);
      setSucceedCount(data.succeed);
      setUserModalConfig({
        type: "complete",
        onClickOutSide: () => handleCloseModal({ callNavigate: true }),
      });
      return;
    }

    setUserModalConfig({
      type: "failed",
      onClickOutSide: () => handleCloseModal({}),
    });

    return;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: false,
    onDropRejected: handleInvalidFileTypeErrorModal,
    // 500MBが上限
    maxSize: 524288000,
    accept: {
      "text/csv": [],
    },
  });

  return (
    <Board>
      <TitleArea>
        <UpdateTitle>CSVでアカウント登録</UpdateTitle>
      </TitleArea>
      <UploadContainer {...getRootProps()}>
        <UploadWrap>
          <Label>CSVファイル</Label>
          <input {...getInputProps()} />
          <Button
            label="ファイルを選択"
            type="secondary"
            width="160px"
            height="40px"
            onClick={open}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Message isBold={selected}>{selected ? fileName : "選択されていません"}</Message>
        </UploadWrap>
      </UploadContainer>
      {!selected ? (
        <PropertyEmptyInfo>
          CSVファイルを選択すると
          <br />
          アップロードして登録するアカウント情報の数を確認できます
        </PropertyEmptyInfo>
      ) : (
        <ContentWrap>
          <ContentArea>
            <ContentLabel>アップロードしてアカウントを登録</ContentLabel>
            <UserInfoWrap>
              <UserCountLabel>{userCount}</UserCountLabel>つのアカウント
            </UserInfoWrap>
          </ContentArea>
        </ContentWrap>
      )}
      <MarginTop />
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate(`/at_port/user`)} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>
            <FontAwesomeIcon icon={faExclamationCircle} size="lg" color={color.warning} />
            登録したアカウント情報は削除することができません
          </Annotation>
          <Button
            type="primary"
            onClick={handleSubmit}
            label="アップロードして登録"
            disabled={!selected}
            width="180px"
          />
        </SaveWrap>
      </FooterArea>
      <AtPortUploadModal
        isVisible={isUserModalShow}
        config={userModalConfig}
        failedCount={failedCount}
        succeedCount={succeedCount}
        uploadCount={uploadCount}
      />
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default ImportUser;
