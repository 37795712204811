import styled from "styled-components";

import Button from "components/Button";
import Loading from "components/Loading";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";

const Body = styled.div`
  width: 600px;
  height: 450px;
  background-color: ${color.white};
  position: relative;
  display: flex;
  justify-content: center;
`;

const Wrap = styled.div`
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CountWrap = styled.div`
  display: flex;
  gap: 20px;
`;

const CountInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  gap: 20px;
  width: 100px;
`;

const Title = styled.div`
  font-size: ${font.size24};
`;

const TopArea = styled.div`
  position: absolute;
  top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: ${font.size16};
`;

const Value = styled.div<{ type?: "success" | "error" | "executing" }>`
  font-size: ${font.size24};
  color: ${(props) => {
    switch (props.type) {
      case "success":
        return color.text.green;
      case "error":
        return color.warning;
      case "executing":
        return color.text.link;
      default:
        return color.text.black;
    }
  }};
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  text-align: center;
`;

const MessageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;

const Message = styled.div`
  font-size: ${font.size20};
`;

export type AtPortUploadModalConfig = Readonly<{
  type: "loading" | "complete" | "failed" | "validation-error";
  onClickOutSide?: () => void;
  onClose?: () => void;
}>;

const AtPortUploadModal = ({
  isVisible,
  config,
  uploadCount,
  failedCount,
  succeedCount,
}: {
  isVisible: boolean;
  config: AtPortUploadModalConfig;
  uploadCount: number;
  succeedCount: number;
  failedCount: number;
}) => {
  const loading = config.type === "loading";
  const showResult = config.type === "complete";
  const validationError = config.type === "validation-error";
  const failed = config.type === "failed";
  return (
    <Modal isVisible={isVisible} onClickOutside={() => config?.onClickOutSide && !loading && config.onClickOutSide()}>
      <Body>
        <Wrap>
          <TopArea>
            <Title>アカウント一括登録進行状況</Title>
          </TopArea>
          {!showResult ? (
            <>
              {validationError && <Message>バリデーションエラー: 不正なデータが含まれています</Message>}
              {failed && <Message>エラー: サーバーエラーが発生しました</Message>}
              {loading && <Loading />}
            </>
          ) : (
            <>
              <CountWrap>
                <CountInfo>
                  <Label>アカウント数</Label>
                  <Value>{uploadCount}</Value>
                </CountInfo>
                <CountInfo>
                  <Label>エラー</Label>
                  <Value type="error">{failedCount}</Value>
                </CountInfo>
                <CountInfo>
                  <Label>成功</Label>
                  <Value type="success">{succeedCount}</Value>
                </CountInfo>
              </CountWrap>
            </>
          )}
          {showResult && (
            <MessageWrap>
              {failedCount > 0 ? (
                <>
                  <div>{failedCount}件のエラーが発生しました</div>
                  エラー詳細については
                  <br />
                  slackの『#atport事業本部-admin通知』 チャンネル
                  <br />
                  を確認してください
                </>
              ) : (
                <>全件の登録に成功しました</>
              )}
            </MessageWrap>
          )}
          <ButtonWrap>
            <Button
              label="閉じる"
              onClick={() => config.onClickOutSide && config.onClickOutSide()}
              disabled={loading}
            />
          </ButtonWrap>
        </Wrap>
      </Body>
    </Modal>
  );
};

export default AtPortUploadModal;
