import { AT_PORT_IMPORT_CSV_ERROR } from "pages/AtPort/constants/csvErrors";
import { templateHeader } from "pages/AtPort/constants/templateHeader";
import { AtPortImportUser } from "pages/AtPort/ImportUser";
import { isEqualArray } from "utils/arrayUtils";

const buildUsers = (data: string[][]): { users: AtPortImportUser[]; userCount: number } => {
  const users = data.map((row) => {
    const [accountId, companyName, contactId, email] = row;
    const isEmpty = [accountId, companyName, contactId, email].some((item) => item === "");
    if (isEmpty) throw new Error(AT_PORT_IMPORT_CSV_ERROR.EMPTY_DATA_INCLUDED.errorCode);
    return {
      accountId,
      companyName,
      contactId,
      email,
    } as AtPortImportUser;
  });

  return {
    users,
    userCount: users.length,
  };
};

export const generateUniqueUserInfoList = async (
  rows: string[][]
): Promise<{
  users: AtPortImportUser[];
  count: number;
}> => {
  return new Promise((resolve, reject) => {
    const [header, ...originalData] = rows;

    // トリム処理と空白行の削除
    const data = originalData
      .map((row) => row.map((item) => item.trim()))
      .filter((row) => row.some((item) => item !== ""));

    // ヘッダーのチェック
    if (!isEqualArray(header, templateHeader)) reject(new Error(AT_PORT_IMPORT_CSV_ERROR.INVALID_HEADER.errorCode));

    // データがない場合
    if (data.length === 0) reject(new Error(AT_PORT_IMPORT_CSV_ERROR.NO_DATA.errorCode));

    const { users, userCount } = buildUsers(data);

    resolve({
      users,
      count: userCount,
    });
  });
};
