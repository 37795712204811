export const AT_PORT_IMPORT_CSV_ERROR = {
  INVALID_HEADER: {
    errorCode: "Invalid header",
    message: "不正なヘッダーです",
  },
  NO_DATA: {
    errorCode: "No data",
    message: "データがありません",
  },
  EMPTY_DATA_INCLUDED: {
    errorCode: "Empty data included",
    message: "空白のデータが含まれています",
  },
} as const;

export type AT_PORT_IMPORT_CSV_ERROR_CODE =
  typeof AT_PORT_IMPORT_CSV_ERROR[keyof typeof AT_PORT_IMPORT_CSV_ERROR]["errorCode"];

export type AT_PORT_IMPORT_CSV_ERROR_KEYS = keyof typeof AT_PORT_IMPORT_CSV_ERROR;
