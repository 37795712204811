import useClient from "hooks/atPort/useClient";
import { AtPortImportUser } from "pages/AtPort/ImportUser";

const useBulkInsertUser = () => {
  const { client } = useClient();

  const bulkInsertUser = async (users: AtPortImportUser[]) => {
    const { status, data } = await client<{ succeed: number; failed: number }, string>({
      method: "POST",
      url: `/user/bulk-insert`,
      data: {
        lesseeUsers: users,
      },
    });
    return { status, data };
  };

  return { bulkInsertUser };
};

export default useBulkInsertUser;
