import type { Analytics } from "firebase/analytics";
import type { Auth, GoogleAuthProvider } from "firebase/auth";
import { Firestore } from "firebase/firestore";
import React from "react";

export const FirebaseContext = React.createContext(
  {} as {
    analytics: Analytics;
    auth: Auth;
    provider: GoogleAuthProvider;
    db: Firestore;
  }
);

export const useFirebaseContext = () => React.useContext(FirebaseContext);
