import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import { Board, TitleArea, Title, Table, Heading, Row, FieldValue, FieldName } from "components/LayoutParts";
import Modal from "components/Modal";
import SearchForm from "components/SearchForm";
import { useFirebaseContext } from "contexts/FirebaseContext";
import { findUser } from "hooks/atPortApi";
import CreateUser from "pages/AtPort/User/CreateUser";

// パーツ定義
const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;
const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: auto;
  gap: 10px;
`;

const filterList = (list: SalesforceUser[], keyword: string): SalesforceUser[] =>
  keyword === "" ? list : list.filter((e) => e.email.indexOf(keyword) + e.companyName.indexOf(keyword) !== -2);

// 本体
const User = () => {
  const navigate = useNavigate();
  const [displayList, setDisplayList] = useState<SalesforceUser[]>([]);
  const [originList, setOriginList] = useState<SalesforceUser[]>([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { auth } = useFirebaseContext();

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
    setDisplayList(filterList(originList, e.target.value));
  };
  const hideModal = () => setIsModalShow(false);
  const showModal = () => setIsModalShow(true);
  const navigateBulkInsert = () => navigate("/at_port/user/bulk_insert");

  const fetch = useCallback(async () => {
    const result = await findUser(auth);
    setDisplayList(filterList(result, searchKeyword));
    setOriginList(result);
  }, [auth, searchKeyword]);

  const onClickRow = async (id: string) => {
    navigate(`/at_port/user/${id}`);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <Board>
        <TitleArea>
          <Title>アカウント一覧</Title>
          <Wrap>
            <Button type="primary" onClick={showModal} label="新規作成" width="160px" />
            <Button type="primary" onClick={navigateBulkInsert} label="CSV一括新規登録" width="200px" />
          </Wrap>
        </TitleArea>
        <ActionArea>
          <SearchForm width="350px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
        </ActionArea>
        <Table>
          <Heading>
            <FieldName flex={1}>顧客ID</FieldName>
            <FieldName flex={1}>顧客名</FieldName>
            <FieldName flex={1}>担当者ID</FieldName>
            <FieldName flex={1}>担当者メールアドレス</FieldName>
          </Heading>
          {displayList.map((user) => (
            <Row key={user.contactId} onClick={() => onClickRow(user.id)}>
              <FieldValue flex={1}>{user.accountId}</FieldValue>
              <FieldValue flex={1}>{user.companyName}</FieldValue>
              <FieldValue flex={1}>{user.contactId}</FieldValue>
              <FieldValue flex={1}>{user.email}</FieldValue>
            </Row>
          ))}
        </Table>
      </Board>
      <Modal isVisible={isModalShow} onClickOutside={hideModal}>
        <CreateUser fetch={fetch} />
      </Modal>
    </>
  );
};

export default User;
